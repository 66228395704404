<template>
  <v-dialog v-model="dialog" width="500" @click:outside="cancel">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="secondary"
          small
          class="justify-start"
        >
          <v-icon left>mdi-flag</v-icon>
          {{ $t("reports.report") }}
        </v-btn>
      </slot>
    </template>

    <v-form v-model="formValid" @submit.prevent="submit" ref="form">
      <v-card class="report-modal">
        <v-card-title>
          <h3>{{ $t("reports.report") }}</h3>
          <v-spacer />
          <close-button @click="cancel" />
        </v-card-title>

        <v-divider />

        <v-card-text>
          <p class="subtitle-1 mb-1">{{ $t("reports.why") }}</p>

          <v-radio-group
            v-model="formData.type"
            hide-details
            :rules="[$rules.required]"
            class="mt-0"
          >
            <v-radio
              v-for="type in $config.reports.types"
              :key="type"
              :value="type"
              class="my-2"
            >
              <template v-slot:label>
                <div class="ml-1">
                  <div>
                    {{ $t(`reports.types.${type}.title`) }}
                  </div>
                  <div class="font-size-12 text--secondary">
                    {{ $t(`reports.types.${type}.description`) }}
                  </div>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            type="submit"
            rounded
            :disabled="!formValid"
            :loading="$loading('reports/send')"
          >
            {{ $t("common.submit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: {
    category: String,
    field: String,
    id: Number,
  },

  data: () => ({
    dialog: false,
    formData: {},
    formValid: false,
  }),

  watch: {
    dialog(val) {
      !val || this.$emit("click");
    },
  },

  created() {
    this.formData.category = this.category;
    this.formData[this.field] = this.id;
  },

  methods: {
    submit() {
      this.$store
        .dispatch("reports/send", this.formData)
        .then(() => {
          this.$toast.success(this.$t("reports.submitted"));
        })
        .catch(({ data }) => {
          if (data.message === "You have already reported this.") {
            this.$toast.warning(this.$t("reports.alreadyReported"));
          } else {
            this.$toast.error("Error while submitting report.");
          }
        })
        .finally(() => {
          this.cancel();
        });
    },

    cancel() {
      this.$refs.form.reset();
      this.dialog = false;
      this.$emit("close");
    },
  },
};
</script>
