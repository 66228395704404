<template>
  <v-btn
    v-if="showable"
    v-bind="$attrs"
    v-on="$listeners"
    :color="$attrs.color || 'primary'"
    :icon="icon"
    :loading="$loading('chat/start')"
    @click="onClick"
  >
    <v-icon :left="!icon">mdi-message-reply-text</v-icon>

    <template v-if="!icon">
      {{ $t("users.message") }}
    </template>
  </v-btn>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    user: Object,
    icon: Boolean,
  },

  computed: {
    showable() {
      return (
        !this.isAuthUser &&
        !this.user.is_blocked &&
        !this.isPrivate &&
        !this.$user.blocked_users.includes(String(this.user.id))
      );
    },

    isAuthUser() {
      return this.user?.id === this.$user.id;
    },

    isPrivate() {
      return this.user.profile_type === "private" && !this.user.is_followed;
    },
  },

  methods: {
    onClick() {
      this.$store
        .dispatch("chat/start", { participants: [this.user.id] })
        .then((conversation) => {
          this.$store.commit("chat/SET_ACTIVE", conversation);
          this.$router.push({
            name: "app.chat",
          });
        })
        .catch(() => {
          this.$toast.error("Error while starting chat.");
        });
    },
  },
};
</script>
