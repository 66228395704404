<template>
  <div class="user-row d-flex align-center">
    <!-- Avatar + Hover -->
    <UserHoverCard :data="user">
      <router-link :to="{ name: 'app.user', params: { id: user.username } }">
        <v-avatar class="avatar mr-3" :size="avatarSize">
          <v-img
            :src="$utils.userImage(user, `${avatarSize}x${avatarSize}`)"
            :width="avatarSize"
            :height="avatarSize"
            :alt="user.username"
          />
        </v-avatar>
      </router-link>
    </UserHoverCard>

    <!-- Username -->
    <div class="flex-grow-1">
      <router-link
        class="name btn-basic"
        :to="{ name: 'app.user', params: { id: user.username } }"
        :style="{ fontSize }"
      >
        {{ user.username }}
      </router-link>
    </div>

    <!-- Actions -->
    <slot name="actions">
      <div v-if="!hideActions" class="actions ml-1">
        <UserFollowButton :user="user" icon unfollowable color="white" />
      </div>
    </slot>

    <!-- Slot:append -->
    <slot name="append" />
  </div>
</template>

<script>
import UserHoverCard from "./UserHoverCard.vue";
import UserFollowButton from "./UserFollowButton.vue";

export default {
  components: { UserHoverCard, UserFollowButton },

  props: {
    user: Object,
    avatarSize: {
      type: [Number, String],
      default: 50,
    },
    fontSize: {
      type: String,
      default: "inherit",
    },
    hideActions: Boolean,
  },
};
</script>

<style></style>
