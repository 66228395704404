<template>
  <v-menu
    offset-y
    open-on-hover
    open-delay="500"
    min-width="300"
    :close-on-content-click="false"
  >
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot></slot>
      </div>
    </template>

    <!-- Content -->
    <div class="user-hover-card ocean-dark pa-4 mt-2 border rounded-lg">
      <div v-if="user" class="d-flex align-center">
        <!-- Avatar -->
        <router-link :to="{ name: 'app.user', params: { id: user.username } }">
          <v-avatar class="avatar mr-3" size="60">
            <v-img
              :src="$utils.userImage(user, '60x60')"
              width="60"
              height="60"
              :alt="user.username"
            />
          </v-avatar>
        </router-link>

        <div>
          <!-- Username -->
          <div class="username font-size-18">
            {{ user.username }}
          </div>

          <!-- Fav club -->
          <div v-if="user.favourite_footbal_club" class="club">
            <v-icon color="primary" size="20">mdi-soccer</v-icon>
            {{ user.favourite_footbal_club.name }}
          </div>
        </div>
      </div>

      <v-row dense v-if="user.id !== $user.id">
        <v-col>
          <UserFollowButton
            :user="user"
            small
            unfollowable
            color="secondary"
            block
            class="mt-3"
          />
        </v-col>

        <v-col>
          <ChatUserButton
            :user="user"
            small
            color="secondary"
            block
            class="mt-3"
          />
        </v-col>
      </v-row>
    </div>
  </v-menu>
</template>

<script>
import UserFollowButton from "./UserFollowButton.vue";
import ChatUserButton from "@/components/app/chat/ChatUserButton.vue";

export default {
  components: { UserFollowButton, ChatUserButton },

  props: {
    id: [Number, String],
    data: Object,
  },

  data: () => ({
    user: null,
  }),

  created() {
    if (this.data) {
      this.user = this.data;
    }
  },
};
</script>

<style></style>
